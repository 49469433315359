<template>
  <v-data-table
    :height="'auto'"
    :headers="headers"
    :items="items"
    dense
    :footer-props="{
      showCurrentPage: true,
      showFirstLastPage: true,
      itemsPerPageOptions: [5, 10, 50, 100],
    }"
    v-bind="propsdinamic"
  >
    <template
      v-for="header in this.headers"
      v-slot:[`item.${header.value}`]="{ item }"
    >
      <span v-if="columnIsNumber(header, item)" :key="header.value"
        >{{ thousandsSeparator(item[header.value]) }}
      </span>
      <span v-else :key="header.value">{{ item[header.value] }}</span>
    </template>
  </v-data-table>
</template> 

<script>
export default {
  components: {},
  props: {
    headers: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default: [],
    },
    propsdinamic: {
      type: Object,
      default: null,
    },
    columnsNotNumber: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    thousandsSeparator(item) {
      return Intl.NumberFormat("es-PE").format(Math.round(item));
    },
    columnIsNumber(header, item) {
      if (this.columnsNotNumber?.includes(header.value)) return false;
      return (
        !isNaN(parseFloat(item[header.value])) && isFinite(item[header.value])
      );
    },
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}

.fixed-first-column thead tr,
.fixed-first-column tbody tr,
.fixed-second-column thead tr,
.fixed-second-column tbody tr,
.fixed-third-column thead tr,
.fixed-third-column tbody tr {
  position: relative;
}
.fixed-first-column thead th:first-child,
.fixed-first-column tbody td:first-child,
.fixed-second-column thead th:nth-child(2),
.fixed-second-column tbody td:nth-child(2),
.fixed-third-column thead th:nth-child(3),
.fixed-third-column tbody td:nth-child(3) {
  position: sticky;
  left: 0;
  background-color: white; /* Ajusta el fondo según tus necesidades */
  z-index: 1;
}
</style>
