<template>
  <div>
    <v-container class="px-0">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" lg="12" md="12">
            <high-charts-drill-bar
              :id="1"
              :items="items"
              :objDrilldown="objDrilldown"
              :visibleSubTitle="true"
              :subtitle="'REPORTE DEVENGADO - RESUMEN'"
              :name="'Cuadrante'"
              :valueSuffix="'USD'"
              :number="true"
              :thousandsSep="true"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import HighChartsDrillBar from "@/components/Utils/HighCharts/HighChartsDrillBar.vue";
export default {
  props: {
    items: {
      type: Array,
      default: [],
    },
    objDrilldown: {
      type: Array,
      default: [],
    },
  },
  components: {
    HighChartsDrillBar,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
