<template>
  <div>
    <v-container>
      <v-container>
        <v-row>
          <v-col cols="12">
            <s-toolbar
              :color="'#244093'"
              dark
              label="Reporte Devengado"
              class="tran"
            ></s-toolbar>
            <v-row style="margin: auto">
              <v-card width="100%">
                <v-row style="margin: auto">
                  <v-col sm="6" cols="6" lg="4" md="4">
                    <s-select-definition
                      :def="1215"
                      v-model="objMounth"
                      return-object
                      label="Seleccione periodo"
                    ></s-select-definition>
                  </v-col>
                  <v-col cols="6" lg="2" md="2" class="mt-3 pl-0">
                    <v-btn
                      width="100%"
                      rounded
                      :color="'info'"
                      small
                      @click="run()"
                      >Buscar</v-btn
                    >
                  </v-col>
                  <v-col cols="6" lg="2" md="2" class="mt-3 pl-0">
                    <v-btn
                      width="100%"
                      rounded
                      :color="'error'"
                      small
                      @click="downloadReport()"
                      :disabled="disabledBtnDownload"
                      >Descargar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </v-col>
        </v-row>

        <v-dialog
          v-model="processing"
          :persistent="messageProcessing.length == 0"
          width="400"
        >
          <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
              Por favor espere
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
              <div class="text--white pt-4">
                Error al realizar busqueda <br />
                {{ messageProcessing }}
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
      <v-container>
        <v-tabs v-model="currentItem">
          <v-tab href="#Dashboard"> Dashboard</v-tab>
          <v-tab href="#Abstract"> Resumen</v-tab>
          <v-tab href="#Detail"> Detalle</v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentItem">
          <v-tab-item :value="'Dashboard'">
            <clc-report-accrued-dashboard
              :items="dataDashboard"
              :objDrilldown="objDrilldown"
            ></clc-report-accrued-dashboard>
          </v-tab-item>
          <v-tab-item :value="'Detail'">
            <v-col cols="12">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="6">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Cuadrante, lote, Dni"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <gral-table
                    :headers="headersDetail"
                    :items="itemsDetail"
                    :columnsNotNumber="['CodigoVenta','Dni' ]"
                    :propsdinamic="{ search, class: 'fixed-second-column' }"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-tab-item>

          <v-tab-item :value="'Abstract'">
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <gral-table
                    :headers="headersAbstract"
                    :items="itemsAbstract"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import _sClcService from "@/services/ReportClicks/ClcChargeMasive.js";
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";

import * as XLSX from "xlsx";
import ClcReportAccruedDashboard from "./ClcReportAccruedDashboard.vue";
import GralTable from "../GralTable.vue";

export default {
  components: { ClcReportAccruedDashboard, GralTable },

  data() {
    return {
      dataDashboard: [],
      objDrilldown: [],
      group: [],
      search: "",
      currentItem: "tab-Funciones",
      processing: false,
      messageProcessing: "",
      itemsDetail: [],
      itemsAbstract: [],
      headersDetail: [],
      headersAbstract: [],
      objMounth: null,
      disabledBtnDownload: true,
      period:''
    };
  },

  methods: {
    run() {
      this.loadAbstract();
      this.loadDetail();
      this.disabledBtnDownload = false;
      this.period = this.objMounth.DedDescription;

    },
    loadAbstract() {
      const report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = `${this.objMounth.DedAbbreviation}`;
      report.QryNameProcedure = "ClcReportAccruedAbastract_R";
      this.processing = true;

      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.processing = false;
            this.headersAbstract = Object.keys(resp.data[0]).map((item) => ({
              text: item,
              value: item,
              width: "120",
              sortable: false,
            }));
            this.itemsAbstract = resp.data;
            const deleteTotal = resp.data.filter(
              (items) => items.Cuadrante !== "Total"
            );
            const sumAmounts = (arreglo, col) =>
              arreglo.reduce((suma, item) => suma + item[col], 0);
            const amounts = Object.entries(
              _.groupBy(deleteTotal, "Cuadrante")
            ).map((item) => ({
              Cuadrante: item[0],
              Total: sumAmounts(item[1], "Total General"),
              "Con Acta": sumAmounts(item[1], "Con Acta"),
              "Sin Acta": sumAmounts(item[1], "Sin Acta"),
            }));
            this.dataDashboard = amounts.map((item) => ({
              name: item.Cuadrante,
              y: item.Total,
              drilldown: item.Cuadrante,
            }));
            this.objDrilldown = amounts.map((item) => ({
              name: item.Cuadrante,
              id: item.Cuadrante,
              data: Object.entries(item).filter(
                (item) => item[0] === "Con Acta" || item[0] === "Sin Acta"
              ),
            }));
          }
        })
        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },
    loadDetail() {
      const report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = `${this.objMounth.DedAbbreviation}`;
      report.QryNameProcedure = "ClcReportAccruedDetail_R";
      this.processing = true;

      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.processing = false;
            this.headersDetail = Object.keys(resp.data[0]).map((item) => ({
              text: item,
              value: item,
              width: "120",
              sortable: false,
            }));
            this.itemsDetail = resp.data;
          }
        })
        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },
    downloadReport() {
      const SheetNames = ["Detalle", "Resumen"];
      const DataTables = [this.itemsDetail, this.itemsAbstract];
      const dataSend = { DataTables, SheetNames };

      this.processing = true;
      _sQryConfigurationService
        .downloadexcelrc(dataSend, this.$fun.getUserID())
        .then(
          (r) => {
            if (r.status == 200) {
              this.processing = false;

              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.EXCEL,
                `Devengado ${this.period}`
              );
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}

.fixed-second-column thead tr,
.fixed-second-column tbody tr {
  position: relative;
}

.fixed-second-column thead th:nth-child(2),
.fixed-second-column tbody td:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: white; /* Ajusta el fondo según tus necesidades */
  z-index: 1;
}
</style>
